<template>
  <div class="settings">
    <v-row>
      <v-col cols="3" class="settings-menu">
        <div v-for="(desc, index) in tabs" :key="`menu-${index}`" class="tw-mb-2">
          <a :class="{active: index === tab}" @click="() => tab = index">{{ desc }}</a>
        </div>
      </v-col>
      <v-col cols="9">
        <div class="tw-border tw-rounded-md tw-px-4 tw-py-6">
          <div class="tw-text-right tw-pb-2 tw-border-b">
            <v-btn small elevation="0" type="submit" color="primary">
              <v-icon class="mr-2">mdi-content-save</v-icon>
              Salvar Configurações
            </v-btn>
          </div>
          <div class="tw-py-2">
            <Global v-show="tab === 0"/>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Global from './global'
// import Api from '@/resources/NajaSocialApi'
// const defaultStartAt = moment().subtract(1, 'months')
// const defaultEndsAt = moment()

export default {
  name: 'Settings',
  components: { Global },

  data() {
    return {
      tab: 0,
      tabs: ['Geral', 'Check-in', 'Confirmação de agendamento', 'Laudo Médico', 'Aviso de agendamento virtual', 'Cartão virtual de entrega', 'Agendamento online de exames', 'Agendamento online de consultas', 'Portal de resultados']
    }
  }

  // watch: {
  //   form: {
  //     handler() {
  //       this.search()
  //     },
  //     deep: true,
  //   },
  // },

  // filters: {
  //   date: function (value) {
  //     return moment(value).format('DD/MM/YYYY HH:mm:ss')
  //   },
  //   status: function (value) {
  //     return {
  //       1: 'Enviada',
  //       2: 'Recebida',
  //       3: 'Respondida',
  //     }[value]
  //   },
  // },

  // data() {
  //   return {
  //     searchTerm: '',
  //     loading: false,
  //     items: [],
  //     defaultStartAt: defaultStartAt.format('DD/MM/YYYY'),
  //     defaultEndsAt: defaultEndsAt.format('DD/MM/YYYY'),
  //     form: {
  //       lot: null,
  //       send_date_gteq: null,
  //       send_date_lteq: null,
  //     },
  //     pagination: {
  //       current_page: 1,
  //       total_entries: 0,
  //     },
  //     headers: [
  //       { text: 'Código', value: 'id' },
  //       { text: 'Data', value: 'send_date' },
  //       {
  //         text: 'Destinatário',
  //         value: 'receiver',
  //       },
  //       { text: 'Status', value: 'status_message' },
  //       { text: 'Processo', value: 'event_type' },
  //       { text: 'Lote', value: 'lot' },
  //     ],
  //     footerProps: {
  //       itemsPerPageOptions: [10, 20, 30, -1],
  //     },
  //   }
  // },

  // mounted() {
  //   // this.fetch()
  //   // this.search()
  // },

  // methods: {
  //   chageCreationInterval(interval) {
  //     this.form.send_date_gteq = interval[0]
  //     this.form.send_date_lteq = interval[1]
  //   },

  //   search() {
  //     this.loading = true

  //     const query = {
  //       q: {
  //         s: ['created_at desc'],
  //         lot_eq: this.form.lot,
  //       },
  //     }

  //     if (
  //       this.form.send_date_gteq != null &&
  //       this.form.send_date_lteq != null
  //     ) {
  //       query.q.send_date_gteq = this.form.send_date_gteq
  //       query.q.send_date_lteq = this.form.send_date_lteq
  //     }

  //     Api.notifications
  //       .search(query)
  //       .then(response => {
  //         this.items = response.data.notifications
  //       })
  //       .catch(error => {
  //         if (error.response?.status === 400) {
  //           this.$root.$children[0].toast(
  //             error.response.data.error || error.response.data.warn
  //           )
  //         }
  //       })
  //       .finally(() => {
  //         this.loading = false
  //         this.$scrollTop()
  //       })
  //   },
  // },
}
</script>

<style scoped lang="scss">
.settings {
  background-color: var(--nj-component-bg);
  color: var(--nj-component-text-color);
  border-radius: var(--nj-component-radius);
  padding: 1rem 45px;
  // display: flex;

  .settings-menu {
    a {
      color: #464646;
      &:hover {
        color: var(--nj-primary-color);
        text-decoration: underline;
      }
      &.active {
        color: var(--nj-primary-color)
      }
    }
  }
}
</style>
